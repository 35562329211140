import React, { useState, forwardRef, useEffect } from 'react';
import ICON_HOME from '../assets/icon_top_home.svg';
import ICON_MYINFO from '../assets/icon_top_myinfo.svg';
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../App.css';
import { ko } from 'date-fns/locale';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import useScript from "../hook/useScript";
import * as Utils from "../common/Utils";

function ReservationPage() {
  const loading = useScript("https://pay.nicepay.co.kr/v1/js/");
  const AUTHNICE = window.AUTHNICE;

  const navigate = useNavigate();
  const location = useLocation();

  const selectDate = location.state.selectDate;

  const [selectedCount, setSelectedCount] = useState(false);
  const [adultCount, setAdultCount] = useState(0);
  const [childCount, setChildCount] = useState(0);


  const [selectedSeat, setSelectedSeat] = useState(false);
  const [selectedSeatList, setSelectedSeatList] = useState([]);

  function getDayToName(day) {
    switch (day) {
      case 0:
        return "일";
      case 1:
        return "월";
      case 2:
        return "화";
      case 3:
        return "수";
      case 4:
        return "목";
      case 5:
        return "금";
      case 6:
        return "토";
    }
  }

  function nicepayTest() {
    AUTHNICE.requestPay({
      clientId: 'S2_a5717a0260114dad8fc2e605457d9b14',
      method: 'card',
      orderId: '1',
      amount: 1000 * (adultCount + childCount),
      goodsName: '나이스페이-상품',
      returnUrl: 'https://wleh.xyz',
      fnError: (e) => {
        console.log(e);
      },
    });
  }

  return (
    <div className="flex flex-col w-full h-svh items-center bg-white justify-center">
      <div className='fixed flex flex-row top-0 gap-1 w-[720px] md:w-full bg-[#F2F3F5] text-[black] px-10 pt-5 md:px-[10px] md:pt-1 items-center'>
        <span className='p-3 cursor-pointer' onClick={() => {
          navigate("/");
        }}>
          <img src={ICON_HOME} width={40} />
        </span>
        <div className='flex w-full font-bold z-[1] justify-center'>
          <h2 className='text-[30px] md:text-[20px]'>예약 신청</h2>
        </div>
        <span className='p-3 cursor-pointer'>
          <img src={ICON_MYINFO} width={40} />
        </span>
      </div>
      <div className='flex flex-col h-full w-[720px] md:w-full bg-[#F2F3F5]'>

        <div className='flex h-full w-full'>
          <div className='scrollRemoveBox flex flex-col h-full w-full items-center pt-[100px] md:pt-[70px] pb-[110px]' id='custom_date_picker'>
            <div className='w-[618px] md:w-full flex flex-col bg-[white] rounded-3xl md:rounded-none p-10 cursor-pointer'>
              <div className='flex flex-row justify-between text-[20px]'>
                <div className='text-[#666666]'>예약일</div>
                <div className='font-bold'>{selectDate?.getFullYear()}.{selectDate?.getMonth() + 1}.{selectDate?.getDate()}({getDayToName(selectDate?.getDay())})</div>
              </div>
            </div>
            <div className='w-[618px] md:w-full flex flex-col bg-[white] rounded-3xl md:rounded-none p-10 mt-[35px] cursor-pointer'>
              <div className='flex flex-row justify-between text-[20px]' onClick={() => {
                setSelectedCount(!selectedCount);
              }}>
                <div className={`${selectedCount ? "text-[#5D49D3] font-bold" : "text-[#666666]"}`}>인원선택</div>
                <div className={`font-bold`}>{adultCount + childCount}명</div>
              </div>
              {
                selectedCount &&
                <div className='flex flex-col mt-[15px]'>
                  <div className='flex flex-row items-center gap-2 mb-[40px]'>
                    <div className='flex w-fit bg-[#F2EDFF] rounded-full text-[#5D49D3] text-[11px] font-bold px-3 py-0 items-center'>소인</div>
                    <div className='text-[13px] text-[#717171]'>36개월 ~ 만12세</div>
                  </div>
                  <div className='flex flex-col gap-5'>
                    <div className='flex flex-row justify-between items-center'>
                      <div>대인</div>
                      <div className='flex flex-row border-[#EAEAEC] border-[1px] p-2 rounded-lg items-center justify-between font-bold'>
                        <div className='flex' onClick={(e) => {
                          let tmpCount = Number(adultCount - 1);
                          if (tmpCount < 0)
                            setAdultCount(0);
                          else {
                            setAdultCount(tmpCount);
                            // 인원수 대응하여 좌석 수정
                            if (selectedSeatList?.length > (childCount + tmpCount)) {
                              setSelectedSeatList(selectedSeatList.slice(0, childCount + tmpCount));
                            }
                          }
                          e.stopPropagation();
                        }}>
                          <FontAwesomeIcon icon={faMinus} style={{
                            color: adultCount === 0 ? "#BABABA" : "#523DD0",
                          }} />
                        </div>
                        <div className='flex justify-center'>
                          <input
                            className='w-full text-center text-[black] focus:outline-none'
                            size={10}
                            placeholder='0'
                            value={adultCount}
                            onChange={(e) => {
                              let resetCount = 0;

                              if (Number(e.target.value) + childCount > 46) {
                                resetCount = 45 - childCount;
                                setAdultCount(resetCount);
                              }
                              else {
                                resetCount = childCount + Number(e.target.value);
                                setAdultCount(Number(e.target.value));
                              }

                              setSelectedSeatList(selectedSeatList.slice(0, resetCount));
                            }}
                          />
                        </div>
                        <div className='flex' onClick={(e) => {
                          let tmpCount = adultCount + 1;

                          if (tmpCount + childCount < 46)
                            setAdultCount(tmpCount);

                          e.stopPropagation();
                        }}>
                          <FontAwesomeIcon icon={faPlus} style={{ color: "#523DD0", }} />
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-row justify-between items-center'>
                      <div>소인</div>
                      <div className='flex flex-row border-[#EAEAEC] border-[1px] p-2 rounded-lg items-center justify-between font-bold'>
                        <div className='flex' onClick={(e) => {
                          let tmpCount = Number(childCount - 1);
                          if (tmpCount < 0)
                            setChildCount(0);
                          else {
                            setChildCount(tmpCount);

                            // 인원수 대응하여 좌석 수정
                            if (selectedSeatList?.length > (adultCount + tmpCount)) {
                              setSelectedSeatList(selectedSeatList.slice(0, adultCount + tmpCount));
                            }
                          }
                          e.stopPropagation();
                        }}>
                          <FontAwesomeIcon icon={faMinus} style={{
                            color: childCount === 0 ? "#BABABA" : "#523DD0",
                          }} />
                        </div>
                        <div className='flex justify-center'>
                          <input
                            className='w-full text-center text-[black] focus:outline-none'
                            size={10}
                            placeholder='0'
                            value={childCount}
                            onChange={(e) => {
                              let resetCount = 0;

                              if (Number(e.target.value) + adultCount > 46) {
                                resetCount = 45 - adultCount;
                                setChildCount(resetCount);
                              }
                              else {
                                resetCount = adultCount + Number(e.target.value);
                                setChildCount(Number(e.target.value));
                              }

                              setSelectedSeatList(selectedSeatList.slice(0, resetCount));
                            }}
                          />
                        </div>
                        <div className='flex' onClick={(e) => {
                          let tmpCount = childCount + 1;

                          if (tmpCount + adultCount < 46)
                            setChildCount(tmpCount);

                          e.stopPropagation();
                        }}>
                          <FontAwesomeIcon icon={faPlus} style={{ color: "#523DD0", }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className='w-[618px] md:w-full flex flex-col bg-[white] rounded-3xl md:rounded-none p-10 mt-[35px] cursor-pointer'>
              <div className='flex flex-row justify-between text-[20px]' onClick={() => {
                setSelectedSeat(!selectedSeat);
              }}>
                <div className={`${selectedSeat ? "text-[#5D49D3] font-bold min-w-fit" : "min-w-fit text-[#666666]"}`}>좌석선택({selectedSeatList?.length})</div>
                <div className='scrollRemoveBox ml-3 font-bold overflow-scroll'>{selectedSeatList?.toString()}</div>
              </div>
              {
                selectedSeat &&
                <div>
                  <div className='flex mt-5 w-full justify-center font-bold'>FRONT</div>
                  <div className='grid grid-cols-5 justify-center mt-5 px-20 md:px-10 font-bold'>
                    <div className='flex justify-center items-center'>A</div>
                    <div className='flex justify-center items-center'>B</div>
                    <div className='flex justify-center items-center'>C</div>
                    <div className='flex justify-center items-center'>D</div>
                    <div className='flex justify-center items-center'>E</div>
                  </div>
                  {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(seatNumber => {
                      return (
                        <div key={seatNumber} className='grid grid-cols-5 justify-center mt-5 px-20 md:px-10'>
                          {
                            ["A", "B", "C", "D", "E"].map(seatLetter => {
                              if (seatLetter !== "C" || (seatNumber === 10 && seatLetter === "C"))
                                return (
                                  <div key={seatLetter} className='flex justify-center items-center'>
                                    <div className='flex w-10 h-10 rounded-t-xl justify-center items-center text-white'
                                      style={{
                                        background: selectedSeatList?.find(item => item === `${seatLetter}${seatNumber}`) !== undefined ? "#523dd0" : "#c1c1c1"
                                      }}
                                      onClick={() => {
                                        let findItem = `${seatLetter}${seatNumber}`;
                                        if (selectedSeatList.find(item => item === findItem) === undefined) {
                                          if (adultCount + childCount > selectedSeatList.length)
                                            // useState 현재 배열 값 유지, 값 추가하는 방법
                                            setSelectedSeatList([...selectedSeatList, findItem]);
                                        }
                                        else {
                                          setSelectedSeatList(selectedSeatList.filter(item => item !== findItem));
                                        }
                                      }}>
                                      {seatNumber}
                                    </div>
                                  </div>
                                )
                              else
                                return <div key={seatLetter} className='flex justify-center items-center' />
                            })
                          }
                        </div>
                      )
                    })
                  }
                  <div className='flex mt-5 w-full justify-center font-bold'>REAR</div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div id="footer" className='fixed bottom-0 flex flex-col gap-0 w-[720px] md:w-full py-[20px] md:py-5 px-[15px] md:px-[0px] z-[1] bg-[white] rounded-t-3xl md:text-[13px]'>
        <div className='flex flex-row w-full justify-between px-10 md:px-6 items-center'>
          <div className='flex flex-col'>
            <div className='text-[#666666]'>총 예상 비용</div>
            <div className='text-[24px] md:text-[20px] font-bold'>{Utils.formatNumber((adultCount + childCount) * 1000)}원</div>
          </div>
          <div className='px-20 md:px-5 py-5 md:py-3 rounded-2xl text-[white] font-bold cursor-pointer'
            style={{
              background: (adultCount + childCount) > 0 ? "#523dd0" : "#EAEAEC",
              color: (adultCount + childCount) > 0 ? "#FFFFFF" : "#BABABA",
            }}
            onClick={() => {
              let totalCount = adultCount + childCount;
              let totalSeat = selectedSeatList.length;
              if (totalCount > 0 && totalCount === totalSeat) {
                nicepayTest();
              }
              else {
                if (totalCount <= 0) alert('인원을 확인해주세요');
                else if (totalCount !== totalSeat) alert('인원에 맞게 좌석을 선택해주세요');
              }
            }}
          >예약하기</div>
        </div>
      </div>
    </div>
  );
}

export default ReservationPage;
