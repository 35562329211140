import React, { useState, useEffect, } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../App.css';
import { ko } from 'date-fns/locale';

function RouteListItem(Props) {
  const [selected, setSelected] = useState(false);
  const routeInfo = Props.route;

  const onChange = (dates) => {
    Props.setSelectDate(dates);
  };

  useEffect(() => {
    // 반드시 다른 항목 선택 시 초기화 처리, 현재 상태와 다르게 동작하는 경우 발생함
    if (Props.selectRoute !== routeInfo.name) {
      setSelected(false);
      Props.setSelectDate("");
    }
  }, [Props.selectRoute]);

  return (<div className="w-[618px] md:w-full flex flex-col bg-[white] rounded-3xl md:rounded-none p-10 cursor-pointer" style={{
    backgroundImage: `url(${routeInfo.bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "right",
  }}
    onClick={() => {
      Props.setSelectRoute(routeInfo.name);
      setSelected(!selected);
    }}
  >
    <div className='flex flex-row justify-between text-[20px] mb-[25px]'>
      <div className='text-[white] font-bold bg-[black] rounded-lg px-3 py-1'>{routeInfo.name}</div>
      <div className='font-bold' onClick={(e) => {
        e.stopPropagation();
      }}></div>
    </div>
    {
      selected &&
      <div className='flex h-full w-full justify-center items-center' id='custom_date_picker'
        style={{
          display: Props.selectRoute === routeInfo.name ? "flex" : "none",
        }}
        onClick={(e) => {
          // 이벤트 버블링 방지
          e.stopPropagation();
        }}>
        <DatePicker
          fixedHeight
          selected={Props.selectDate}
          onChange={onChange}
          startDate={Props.selectDate}
          shouldCloseOnSelect
          locale={ko}
          minDate={Props.startDate}
          dateFormat="yyyy/MM/dd"

          inline
          renderCustomHeader={(dateInstance) => (
            <div className='flex flex-row justify-between px-10 md:px-[25px] py-2 items-center text-[18px] text-[#523dd0] font-bold'>
              <button className='text-[gray]'
                onClick={(e) => {
                  dateInstance.decreaseMonth();
                }}>
                {"<"}
              </button>
              <div>{dateInstance.date.getFullYear()}.{dateInstance.date.getMonth() + 1}</div>
              <button className='text-[gray]'
                onClick={() => {
                  dateInstance.increaseMonth();
                }}>
                {">"}
              </button>
            </div>
          )}
        />
      </div>
    }
  </div>);
}

export default RouteListItem;