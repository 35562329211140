import React, { } from 'react';
import './App.css';
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import HomePage from './main/HomePage';
import CalendarPage from './reservation/CalendarPage';
import ReservationPage from './reservation/ReservationPage';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/calendar" element={<CalendarPage />} />
          <Route path="/reserve" element={<ReservationPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
