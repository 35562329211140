import React, { useState, useEffect } from 'react';
import '../App.css';
import MAIN_IMG1 from '../assets/main_img.jpg';
import MAIN_IMG2 from '../assets/main_img2.jpg';
import MAIN_IMG3 from '../assets/main_img3.jpg';
import MAIN_IMG4 from '../assets/main_img4.jpg';
import ICON_DATE from '../assets/icon_date.svg';
import ICON_MYINFO from '../assets/icon_myinfo.svg';
import ICON_RIGHT from '../assets/icon_right.svg';
import ICON_RIGHT2 from '../assets/icon_right2.svg';
import { useNavigate } from "react-router-dom";

function HomePage() {
  const navigate = useNavigate();

  const [source, setSource] = useState(MAIN_IMG1);
  let IMG_LIST = [MAIN_IMG1, MAIN_IMG2, MAIN_IMG3, MAIN_IMG4];
  let index = 1;

  useEffect(() => {
    const interval = setInterval(() => {
      setSource(IMG_LIST[index++]);
      if (index > 3)
        index = 0;
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-col h-svh items-center bg-[white] justify-center">
      <img className='absolute h-svh w-[720px] object-none' src={source} />
      <div className='flex flex-col bg-[white] h-full w-[720px] md:w-full text-[white] px-10 md:px-[20px] pt-[200px] md:pt-[50px] pb-12'>
        <div className='h-full font-bold z-[1]'>
          <h2 className='text-[35px] md:text-[17.5px]'>글로벌컴퍼니</h2>
          <div className='text-[70px] md:text-[35px]'>버스 예약</div>
        </div>

        <div className='flex flex-row md:flex-col gap-5 z-[1]'>
          <div className='flex flex-col gap-2 w-[50%] md:w-full bg-[slateblue] px-10 md:px-5 py-5 rounded-md cursor-pointer select-none'
            onClick={() => {
              navigate("/calendar");
            }}
          >
            <div className='flex flex-row gap-1 text-[white] font-bold text-[20px] md:text-[15px] items-center justify-between'>
              <div className='flex flex-row items-center gap-1'>
                <span>
                  <img src={ICON_DATE} />
                </span>
                <div>예약 신청</div>
              </div>
              <span>
                <img src={ICON_RIGHT} />
              </span>
            </div>
            <div className='text-[#afafaf] text-[13px] md:text-[11px]'>
              선택된 날짜별 인원을 모집하여 정원을 채우는 경우 버스 사용이 가능합니다.
            </div>
          </div>

          <div className='flex flex-col gap-2 w-[50%] md:w-full bg-[white] px-10 md:px-5 py-5 rounded-md cursor-pointer select-none'>
            <div className='flex flex-row gap-1 text-[slateblue] font-bold text-[20px] md:text-[15px] items-center justify-between'>
              <div className='flex flex-row items-center gap-1'>
                <span>
                  <img src={ICON_MYINFO} />
                </span>
                <div>예약 내역</div>
              </div>
              <span>
                <img src={ICON_RIGHT2} />
              </span>
            </div>
            <div className='text-[#afafaf] text-[13px] md:text-[11px]'>
              예약한 정보를 확인하고 변경 또는 취소할 수 있습니다.
            </div>
          </div>
        </div>

      </div>

      <div id="footer" className='flex flex-col gap-0 w-[720px] md:w-full py-[50px] px-[40px] md:px-[20px] md:py-5 z-[1] bg-[white] md:text-[13px]'>
        <div className='flex flex-row gap-2 w-full items-start'>
          <div className='font-bold'>등록 신청</div>
          <div>02-222-2222</div>
        </div>
        <div>
          CopyrightⓒGOBAL COMPANY
        </div>
      </div>
    </div>
  );
}

export default HomePage;
