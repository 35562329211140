import React, { useState } from 'react';
import ICON_HOME from '../assets/icon_top_home.svg';
import ICON_MYINFO from '../assets/icon_top_myinfo.svg';
import { useNavigate } from "react-router-dom";
import RouteListItem from './RouteListItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

function CalendarPage() {
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(new Date());
  const [selectDate, setSelectDate] = useState("");
  const [selectRoute, setSelectRoute] = useState("");

  const routeList = [{
    name: "서울",
    bg: "/assets/route_seoul.jpeg"
  }, {
    name: "남이섬",
    bg: "/assets/route_nami.jfif"
  }, {
    name: "설악산",
    bg: "/assets/route_sorak.jpg"
  }, {
    name: "전주",
    bg: "/assets/route_jeonju.jpg"
  }, {
    name: "경주",
    bg: "/assets/route_kyungju2.png"
  }, {
    name: "부산",
    bg: "/assets/route_busan.jpg"
  },]

  return (
    <div className="flex flex-col w-full h-svh items-center bg-[white] justify-center">
      <div className='flex flex-col h-full w-[720px] md:w-full bg-[#F2F3F5]'>
        <div className='fixed flex flex-row top-0 gap-1 w-[720px] md:w-full bg-[#F2F3F5] text-[black] px-10 pt-5 md:px-[10px] items-center'>
          <span className='p-3 cursor-pointer' onClick={() => {
            navigate("/");
          }}>
            <img src={ICON_HOME} width={40} />
          </span>
          <div className='flex w-full font-bold z-[1] justify-center'>
            <h2 className='text-[30px] md:text-[20px]'>예약 신청</h2>
          </div>
          <span className='p-3 cursor-pointer'>
            <img src={ICON_MYINFO} width={40} />
          </span>
        </div>

        <div className='scrollRemoveBox flex flex-col gap-5 h-full w-full items-center pt-[100px] pb-[110px]'>
          {
            routeList.map((route, index) => {
              return (
                <RouteListItem key={index}
                  route={route}
                  selectRoute={selectRoute}
                  setSelectRoute={setSelectRoute}
                  selectDate={selectDate}
                  setSelectDate={setSelectDate}
                  startDate={startDate} />
              )
            })
          }
        </div>

        <div id="footer" className='fixed bottom-0 flex flex-col gap-0 w-[720px] md:w-full py-[20px] md:py-5 px-[15px] z-[1] bg-[white] md:text-[13px]'>
          <div className='flex w-full p-4 rounded-lg justify-center text-[white] font-bold cursor-pointer'
            style={{
              background: selectDate !== "" ? "#523DD0" : "#EAEAEC",
              color: selectDate !== "" ? "#FFFFFF" : "#BABABA",
            }}
            onClick={() => {
              if (selectDate !== "")
                navigate("/reserve", { state: { selectDate: selectDate } });
            }}>
            {selectDate !== "" ? `${selectRoute} ${selectDate?.getMonth() + 1}월 ${selectDate?.getDate()}일 선택완료` : "날짜를 선택하세요"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalendarPage;
